import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { apiClient } from "../api-client";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";

export const SignInPage = () => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const login = async () => {
    let token = await apiClient.login(username, password);
    if (token != null) {
      localStorage.setItem("token", token);
    }
    location.href = "/";
  };

  return (
    <Layout>
      <SEO title="Sign Up" />
      <Box p="4" width="100%">
        <Heading size="xl">Welcome back!</Heading>
        <Divider my="4" />
        <FormControl id="username" mb="4">
          <InputGroup>
            <InputLeftAddon children="@" />
            <Input
              type="username"
              placeholder="veld"
              onChange={e => setUsername(e.target.value)}
            />
          </InputGroup>
        </FormControl>
        <FormControl id="username" mb="4">
          <Input
            type="password"
            placeholder="It's a secret!"
            onChange={e => setPassword(e.target.value)}
          />
        </FormControl>
        <Button margin="sm" bgColor="blue.400" onClick={login}>
          Sign In
        </Button>
      </Box>
    </Layout>
  );
};

export default SignInPage;
